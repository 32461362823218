.users {
  max-width: 100rem;
  margin: auto;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.4);
}

.users h2 {
  text-align: center;
}

.action {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 4rem;
  margin-top: 2rem;
}

@media (max-width: 56.25em) {
  .list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 38.75em) {
  .list {
    grid-template-columns: repeat(1, 1fr);
  }
}
