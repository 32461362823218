.alert {
  position: fixed;
  top: 8rem;
  right: 0;
  left: 0;
  max-width: 80rem;
  padding: 2rem 3rem;
  margin: auto;
  text-align: center;
  color: #fff;
  font-size: 1.4rem;
  border-radius: 8px;
  z-index: 2000;
}

.success {
  background-color: green;
}

.error {
  background-color: red;
}
