.home {
  margin: auto;
  max-width: 100rem;
  width: 100%;
  height: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
}

.home a {
  background-color: blue;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 12px;
  font-size: 1.4rem;
  text-decoration: none;
}
