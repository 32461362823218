.footer {
  margin: 0 auto;
  padding: 2rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  margin-top: 8rem;
  position: sticky;
  bottom: 0;
  background-color: white;
}

.footer p {
  font-size: 1.4rem;
  text-align: center;
}
