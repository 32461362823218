.form {
  max-width: 80rem;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  padding: 2rem;
  position: relative;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.group {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  gap: 1rem;
  position: relative;
}

.input-group {
  max-width: 80rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 0.4rem;
}

.icon {
  width: 3.8rem;
  height: 3.8rem;
  border-right: none;
  color: #fff;
  border-radius: 8px;
  margin-right: 0.2rem;
}

.group label {
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.group input {
  max-width: 80rem;
  width: 100%;
  border: 3px solid black;
  height: 3.8rem;
  font-size: 1.8rem;
  padding: 0 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  outline: none;
  border: none;
  border-radius: 8px;
}

.action button {
  font-size: 2.4rem;
  font-weight: 400;
  background-color: #087f5b;
  padding: 1rem 4rem;
  border-radius: 8px;
  color: #fff;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  transition: all 500ms;
  margin-top: 4rem;
}

.action button:hover {
  transform: scale(1.2);
}

.action button:disabled,
.action button:disabled:hover,
.action button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.invalid label {
  color: red;
}
