.header {
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.4);
  padding: 0 2rem;
  margin-bottom: 4rem;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
}

.logo {
  width: 8rem;
  height: 8rem;
  cursor: pointer;
}

.logo img {
  width: 100%;
  height: 100%;
}

.header a {
  display: block;
  padding: 1rem 2rem;
  font-size: 2rem;
  background-color: blue;
  color: white;
  border-radius: 12px;
  text-decoration: none;
}
