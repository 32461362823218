.investments {
  margin: auto;
}

.loading {
  margin: auto;
  max-width: 70rem;
  width: 100%;
  height: 60rem;
  border-radius: 12px;
  overflow: hidden;
}

.empty {
  margin: auto;
  max-width: 70rem;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #94d82dc5, #087f5bb6, #087f5bb6);
  height: 60rem;
  border-radius: 12px;
}

.empty p {
  font-size: 2rem;
  color: #fff;
  text-align: center;
}
