.error {
  max-width: 80rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 8rem 4rem;
  background-color: rgba(0, 0, 0, 0.5);
}

.error p {
  font-size: 2rem;
  color: #fff;
  background-color: #c92a2a;
  padding: 2rem;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 8px;
  text-align: center;
}
