.form {
  max-width: 80rem;
  margin: auto;
  background-color: #fff;
  border-radius: 12px;
  padding: 2rem;
  position: relative;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.group {
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
  gap: 1rem;
  position: relative;
}

.input-group {
  max-width: 80rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  border-radius: 8px;
  padding: 0.4rem;
}

.icon {
  width: 3.8rem;
  height: 3.8rem;
  border-right: none;
  color: black;
  border-radius: 8px;
  margin-right: 0.2rem;
}

.group label {
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.group input {
  max-width: 80rem;
  width: 100%;
  border: 1px solid black;
  height: 3.8rem;
  font-size: 1.8rem;
  padding: 0 1rem;
  background-color: #fff;
  color: black;
  outline: none;
  border-radius: 8px;
}

.action button {
  font-size: 2.4rem;
  font-weight: 400;
  background-color: #087f5b;
  padding: 1rem 4rem;
  border-radius: 8px;
  color: #fff;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  transition: all 500ms;
}

.action button:hover {
  transform: scale(1.2);
}

.action button:disabled,
.action button:disabled:hover,
.action button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.invalid label {
  color: #b40e0e;
}

.scaleIn {
  transform: scale(0.7);
  transition: all 0.3s;
}

.form span {
  color: #fff;
  font-size: 1.4rem;
  background-color: #b40e0e;
  padding: 0.5rem;
  display: inline-block;
  margin-bottom: 2rem;
  position: absolute;
  bottom: -6rem;
}

@media (max-width: 46.9em) {
  .group label {
    font-size: 1.2rem;
  }
  .group input {
    border: 1px solid black;
    height: 3rem;
    font-size: 1.4rem;
  }
}

@media (max-width: 34.5em) {
  .form span {
    font-size: 0.8rem;
  }

  .input-group {
    padding: 0;
  }
}
