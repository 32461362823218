.user {
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  position: relative;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content span {
  font-size: 1.2rem;
}

.icon {
  width: 2rem;
  height: 2rem;
  color: red;
  cursor: pointer;
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
}
